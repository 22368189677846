import React, { useState } from 'react'
import Hero from '../../common/Hero/Hero'

function HeroView() {
    return (
        <div className='hero-view'>
            <Hero />
        </div>
    )
}

export default HeroView