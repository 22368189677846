import React, { useState } from 'react';
import * as AppImg from '../../../static/constants/imgList'
import { useAccount, useDisconnect } from 'wagmi';
import {FaBars,FaTimes} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function Header({ activeNav, setActiveNav, setIsModalClosed }) {

    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

    const navigate = useNavigate();
    
    const { address, isConnected } = useAccount()

    const { disconnect } = useDisconnect()

    return (
        <div className="header px-5 d-flex justify-content-between align-items-center">
            <div className="header-logo cursor-pointer px-3">
                <img src={AppImg.Logo} alt="logo" className='header-logo-img' />
            </div>
            <div className='nav-bar position-relative d-flex justify-content-center align-items-center px-3'>
                <div className="d-md-flex d-none justify-content-center align-items-center mx-4">
                    <h4 
                        className={activeNav == 'mint' ? "active-nav nav-buttons cursor-pointer mx-3 active" : "nav-buttons cursor-pointer mx-3 active" } 
                        onClick={() => {setActiveNav('mint');navigate('/mint');}}
                    >
                        Mint
                    </h4>
                    <h4 
                        className={activeNav == 'cham-mint' ? "active-nav nav-buttons cursor-pointer mx-3 active" : "nav-buttons cursor-pointer mx-3 active" } 
                        onClick={() => {setActiveNav('cham-mint');navigate('/cham-mint');}}
                    >
                        Cham Mint
                    </h4>
                    <h4 
                        className={activeNav == 'collab-mint' ? "active-nav nav-buttons cursor-pointer mx-3 active" : "nav-buttons cursor-pointer mx-3 active" } 
                        onClick={() => {setActiveNav('collab-mint');navigate('/collab-mint');}}
                    >
                        Collab Mint
                    </h4> 
                </div>
                <div className="d-sm-flex d-none justify-content-center align-items-center">
                {!isConnected ?
                    <button 
                        className="btn btn-primary rounded-0"
                        role="button"
                        onClick={() => {setIsModalClosed(true)}}
                    >
                        CONNECT WALLET
                    </button>
                    :
                    <button className="btn btn-primary rounded-0" onClick={() => {disconnect()}}>DISCONNECT</button>
                }
                </div>
                <div className='nav-btn d-md-none d-block cursor-pointer px-3' onClick={() => setIsNavMenuOpen(!isNavMenuOpen)}>
                    { isNavMenuOpen ? 
                        <FaTimes size={22}/>
                        :
                        <FaBars size={22}/>
                    }
                </div>
                <div className={isNavMenuOpen ? 'd-flex d-md-none flex-column justify-content-center align-items-start position-absolute nav-bar-sm shadow-sm bg-light p-3 rounded' : "d-none"}>
                    <a className='cursor-pointer my-2 text-decoration-none text-dark px-3' href='/mint'>Mint</a>
                    <a className='cursor-pointer my-2 text-decoration-none text-dark px-3' href='/cham-mint'>Cham Mint</a>
                    <a className='cursor-pointer mt-2 mb-4 text-decoration-none text-dark px-3' href='/collab-mint'>Collab Mint</a>
                    <div className="d-flex d-sm-none justify-content-center align-items-center">
                    {!isConnected ?
                        <button 
                            className="btn btn-primary rounded-0"
                            role="button"
                            onClick={() => {setIsModalClosed(true)}}
                        >
                            CONNECT WALLET
                        </button>
                        :
                        <button className="btn btn-primary rounded-0" onClick={() => {disconnect()}}>DISCONNECT</button>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header