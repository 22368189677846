import React from "react";
import { useContractReads } from "wagmi";
import { abi, contractAddress } from '../../strippMFersVendorWeb3';

export function useExcludedChemTokenHook(chamTokens) {

    const { data, isError, isLoading } = useContractReads({
        contracts: [
            {
                address: contractAddress,
                abi: abi,
                functionName: 'getExcludedChemToken',
                args: [chamTokens]
            },
        ],
    });

    return data;
}
