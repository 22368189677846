import React, { useEffect } from 'react'
import MintButton from '../MintButton/MintButton';
import MintingInput from '../MintingInput/MintingInput';

function PublicMintSection({ 
    mintPrice, 
    totalPrice, 
    mintNftHandler, 
    setMintingQty, 
    mintingQty,
    setActiveNav,
    mintLoading
}) {
    useEffect(() => {
        setMintingQty(0);
        setActiveNav('mint');
    }, []);

    return (
        <div className='public-section text-center d-flex flex-column align-items-center justify-content-top mt-5'>
            <h1 className="public-title">Mint StrippMFers</h1>
            <MintingInput mintingQty={mintingQty} setMintingQty={setMintingQty} />
            <h2 className="public-text mt-4">For each : {mintPrice ? mintPrice : " 0"} ETH</h2>
            <h2 className="public-text mt-4"> &nbsp;Total :  {totalPrice ? totalPrice : '0'} ETH</h2>
            <MintButton 
                mintNftHandler={mintNftHandler} 
                isDisabled={mintingQty <= 0}
                mintLoading={mintLoading}
            />
        </div>
    )
}

export default PublicMintSection