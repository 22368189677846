import React, { useEffect, useState } from 'react'
import MintButton from '../MintButton/MintButton';
import MintingInput from '../MintingInput/MintingInput';

function ErcMintSection({ mintNftHandler, 
    setMintingQty, 
    ercMintType, 
    setErcMintType, 
    mintPrice, 
    totalPrice, 
    mintingQty, 
    setMintResultLink, 
    setActiveNav,
    mintLoading,
    mintMsg
}) {

    useEffect(() => {
        setMintingQty(0);
        setActiveNav('collab-mint')
    }, []);

    return (
        <div className='hero'>
            <div className="hero-wrapper w-100">
                <div className="hero-mint-sec text-center w-100 d-flex flex-column align-items-center justify-content-top mt-5">
                    <h1 className="public-title mt-4">Mint strippMFers With ERC20</h1>
                    <div className='mint-input-wrapper'>
                        <select 
                            className="mint-input SecularOneSansSerif-normal-black-24px form-select rounded-2 mb-3 mt-3"
                            onChange={(e) => {setErcMintType(e.target.value);setMintResultLink("")}}
                        >
                            <option className="public-text" value="gas" defaultValue>$Gas</option>
                            <option className="public-text" value="love">$Love</option>
                        </select>
                    </div>
                    <MintingInput mintingQty={mintingQty} setMintingQty={setMintingQty} />
                    <h2 className="public-text mt-4">
                        For each: {mintPrice ? mintPrice : " 0"}{ercMintType === 'gas' ? ' $GAS' : ' $LOVE'}
                    </h2>
                    <h2 className="public-text mt-4">
                        Total :  {totalPrice ? totalPrice : '0'}{ercMintType === 'gas' ? ' $GAS' : ' $LOVE'}
                    </h2>
                    <MintButton 
                        title="Approve and mint" 
                        mintNftHandler={mintNftHandler} 
                        isDisabled={mintingQty <= 0}
                        mintLoading={mintLoading}
                    />
                    {mintMsg &&
                        <h2 className="public-text mt-4"> {mintMsg}</h2>
                    }
                </div>
            </div>
        </div>
    )
}

export default ErcMintSection