import React, { useEffect, useState } from 'react'
import AboutMfers from '../components/common/AboutMfers/AboutMfers'
import ChamMintSection from '../components/common/ChamMintSection/ChamMintSection'
import ErcMintSection from '../components/common/ErcMintSection/ErcMintSection'
import PublicMintSection from '../components/common/PublicMintSection/PublicMintSection'
import HeroView from '../components/main/HeroView/HeroView'
import axios from 'axios';
import { abi, contractAddress, iface, provider } from '../strippMFersVendorWeb3';
import { abi as abiGas, contractAddress as contractAddressGas, nftContract as nftContractGas } from '../gasVendorWeb3';
import { abi as abiLove, contractAddress as contractAddressLove, nftContract as nftContractLove } from '../loveVendorWeb3';
import {useAccount, useContractReads, useContractWrite, useProvider} from 'wagmi';
import { toast } from 'react-toastify';
import { BigNumber, ethers } from 'ethers';
import { TfiArrowTopRight } from 'react-icons/tfi';
import { baseUrlEtherscan, base_url } from '../constant';

function Home({mintType, setActiveNav}) {
    const [mintingQty, setMintingQty] = useState(0);
    const [mintLoading, setMintLoading] = useState(false);
    const [maxMintQty, setMaxMintQty] = useState();
    const [maxMintQtyLoading, setMaxMintQtyLoading] = useState(false);
    const [mintingAmount, setMintingAmount] = useState();
    const [balanceCalc, setBalanceCalc] = useState();
    const [mintAmountLoading, setMintAmountLoading] = useState(false);
    const [mintResultLink, setMintResultLink] = useState('');
    const [ercMintType, setErcMintType] = useState('gas');
    const [selectedTokenIds, setSelectedTokenIds] = useState([]);
    const [listData, setListData] = useState([]);
    const [listDataForHook, setListDataForHook] = useState([]);
    const [mintMsg, setMintMsg] = useState('');

    const { address, isConnected } = useAccount();

    const provider = useProvider()

    const { data, isError, isLoading } = useContractReads({
        contracts: [
            {
                address: contractAddress,
                abi: abi,
                functionName: 'maxMintPerTx',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'maxPerWallet',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'balanceOf',
                // args: [address]
                // overrides: { from: address }
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'price',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'gasPrice',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'lovePrice',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'mintActive',
            },
        ],
    })
    let balance = 0;
console.log("data",data);
console.log("isError",isError);
    useEffect(()=> {
        setMaxMintQtyLoading(isLoading);
        setMintAmountLoading(isLoading);
        if(data && data.length > 0){
            if(mintType === 'public'){
                setMaxMintQty(Number(data[0]));
                setMintingAmount(ethers.utils.formatEther(data[3]))
                let t1 = (1000 * ethers.utils.formatEther(data[3]) * (mintingQty ? mintingQty : 1) * 1000 / 1000);
                let nftPrice1 = (1000000000000000 * t1).toString()
                balance = BigNumber.from(nftPrice1.toString(16));
                setBalanceCalc(BigNumber.from(nftPrice1.toString(16)));
            }else if(mintType === 'cham'){
                setMaxMintQty(Number(data[0]));
                setMintingAmount(0)
                let t1 = (1000 * 0 * (mintingQty ? mintingQty : 1) * 1000 / 1000);
                let nftPrice1 = (1000000000000000 * t1).toString()
                balance = BigNumber.from(nftPrice1.toString(16));
                setBalanceCalc(BigNumber.from(nftPrice1.toString(16)));
            }else if(mintType === 'erc20'){
                setMaxMintQty(Number(data[1]));
                if(ercMintType === 'gas'){
                    setMintingAmount(ethers.utils.formatEther(data[4]));
                    // let t1 = (1000 * ethers.utils.formatEther(data[4]) * (mintingQty ? mintingQty : 1) * 1000 / 1000);
                    // let nftPrice1 = (1000000000000000 * t1)
                    // console.log('nftPrice1 :', t1)
                    // console.log('nftPrice1 wei:', nftPrice1)
                    // balance = BigNumber.from(nftPrice1.toString(16));
                    // setBalanceCalc(nftPrice1);
                    setBalanceCalc(ethers.utils.formatEther(data[4]) * (mintingQty ? mintingQty : 1));
                }else if(ercMintType === 'love'){
                    setMintingAmount(ethers.utils.formatEther(data[5]));
                    // let t1 = (1000 * ethers.utils.formatEther(data[5]) * (mintingQty ? mintingQty : 1) * 1000 / 1000);
                    // let nftPrice1 = (1000000000000000 * t1).toString()
                    // console.log('nftPrice1 :', t1)
                    // console.log('nftPrice1 wei:', nftPrice1)
                    // balance = BigNumber.from(nftPrice1.toString(16));
                    // setBalanceCalc(nftPrice1);
                    setBalanceCalc(ethers.utils.formatEther(data[5]) * (mintingQty ? mintingQty : 1));
                }

            }
        }
    }, [ data, isLoading, mintType, mintingQty, ercMintType ]);

    const publicMintWrite = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: contractAddress,
        abi: abi,
        functionName: 'publicMint',
        args: [mintingQty],
        overrides: {
            value: balanceCalc,
        },
        onSuccess(data) {
            // console.log('publicMint Success', data)
            if(data) {
                setMintResultLink(baseUrlEtherscan+data.hash)
                // console.log("publicMint data.hash :", data.hash);
                setMintLoading(false);
                setMintingQty(0);
            }
        },
        onSettled(data, error) {
            // console.log('publicMint Settled', { data, error })
        },
        onError(error) {
            // console.log('publicMint Error', error)
            // console.log('publicMint error.code', error.code)

            alert('Mint Error: ' +  error.code)
            setMintLoading(false);
        },
    })
    
    const chamMintWrite = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: contractAddress,
        abi: abi,
        functionName: 'mintByChameleons',
        args: [selectedTokenIds],
        onSuccess(data) {
            // console.log('chamMint Success', data)
            if(data && data.hash) {
                setMintResultLink(baseUrlEtherscan+data.hash)
                // console.log("wet-list data.hash :", data.hash);
                setMintLoading(false);
                setMintingQty(0);
            }
        },
        onSettled(data, error) {
            // console.log('chamMint Settled', { data, error })
        },
        onError(error) {
            // console.log('chamMint Error', error)
            alert('Mint Error: ' +  error.code)
            setMintLoading(false);
        },
    })

    // approval for erc20 ($gas token) token 
    // console.log('gas approval contractAddress', contractAddress)
    // console.log('gas approval balanceCalc', balanceCalc)
    // console.log('gas approval Number balanceCalc', Number(balanceCalc))

    const gasApprovalWrite = async (balance) => {
        // console.log("spender :", contractAddress, "amount :", balance);
        try{
            let approveRes = await nftContractGas.approve(contractAddress,balance,{
                from: address
            });
            if(approveRes){
                // console.log("approveRes :", approveRes);
                let isBreak = 0; 
                let interval; 
                interval = setInterval(async () => { 
                    if (isBreak <= 20) {
                        isBreak = isBreak+1;
                        // console.log('isBreak :', isBreak)
                        await provider.getTransactionReceipt(approveRes.hash)
                        .then(function(transactionReceipt) {
                            // console.log(transactionReceipt);
                            let transactionReceipttx = transactionReceipt;
                            // console.log('transactionReceipttx status :', transactionReceipttx?.status)
                            if(transactionReceipttx?.status === 1){
                                gasMintWrite.write();
                                isBreak = 100;
                            }else if(transactionReceipttx?.status === 0){
                                toast.warning('Oops! Approval failed, try again')
                                isBreak = 100;
                            }
                        })
                        .catch((err) => {
                            console.log('error :', err)
                            alert(err);
                        });
                    } else {
                        clearInterval(interval)
                    }
                }, 5000);
            }
            // console.log("approveRes :", approveRes);
        }catch(error){
            console.log("remainingWetList error :", error);
            alert('Approval Error : ' +  error)
            setMintLoading(false)
        }
    }

    // const gasApprovalWrite = useContractWrite({
    //     mode: 'recklesslyUnprepared',
    //     address: contractAddressGas,
    //     abi: abiGas,
    //     functionName: 'approve',
    //     args: [contractAddress, balanceCalc],
    //     onSuccess(data) {
    //         console.log('gas approval Success', data)
    //     },
    //     onSettled(data, error) {
    //         // console.log('gas approval Settled', { data, error });
    //         if(data){
    //             let isBreak = 0; 
    //             let interval; 
    //             interval = setInterval(async () => { 
    //                 if (isBreak <= 20) {
    //                     isBreak = isBreak+1;
    //                     // console.log('isBreak :', isBreak)
    //                     await provider.getTransactionReceipt(data.hash)
    //                     .then(function(transactionReceipt) {
    //                         // console.log(transactionReceipt);
    //                         let transactionReceipttx = transactionReceipt;
    //                         // console.log('transactionReceipttx status :', transactionReceipttx?.status)
    //                         if(transactionReceipttx?.status === 1){
    //                             gasMintWrite.write();
    //                             isBreak = 100;
    //                         }else if(transactionReceipttx?.status === 0){
    //                             toast.warni+ng('Oops! Approval failed, try again')
    //                             isBreak = 100;
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         // console.log('error :', err)
    //                         // alert(err);
    //                     });
    //                 } else {
    //                     clearInterval(interval)
    //                 }
    //             }, 5000);
    //         }
    //     },
    //     onError(error) {
    //         // console.log('gas approval Error', error)
    //         alert('Approval Error : ' +  error.code)
    //         setMintLoading(false)
    //     },
    // })
    
    const gasMintWrite = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: contractAddress,
        abi: abi,
        functionName: 'mintByFungibleTokens',
        args: [mintingQty, 2],
        onSuccess(data) {
            // console.log('erc20Mint Success', data)
            if(data && data.hash) {
                setMintResultLink(baseUrlEtherscan+data.hash)
                // console.log("wet-list data.hash :", data.hash);
                setMintLoading(false);
                setMintingQty(0);
            }
        },
        onSettled(data, error) {
            // console.log('erc20Mint Settled', { data, error })
        },
        onError(error) {
            // console.log('erc20Mint Error', error)
            alert('Mint Error: ' +  error.code)
            setMintLoading(false);
        },
    })
     
    // approval for erc20 ($love token) token 
    // console.log("balanceCalc :", balanceCalc)

    const loveApprovalWrite = async (balance) => {
        // console.log("spender :", contractAddress, "amount :", balance);
        try{
            let approveRes = await nftContractLove.approve(
                contractAddress,
                balance,
                { from: address }
            );
            if(approveRes){
                let isBreak = 0; 
                let interval = setInterval(async () => { 
                    if (isBreak <= 20) {
                        isBreak = isBreak+1;
                        // console.log('isBreak :', isBreak)
                        await provider.getTransactionReceipt(approveRes.hash)
                        .then(function(transactionReceipt) {
                            console.log(transactionReceipt);
                            let transactionReceipttx = transactionReceipt;
                            // console.log('transactionReceipttx status :', transactionReceipttx?.status)
                            if(transactionReceipttx?.status === 1){
                                loveMintWrite.write()
                                isBreak = 100;
                            }else if(transactionReceipttx?.status === 0){
                                toast.warning('Oops! Approval failed, try again')
                                isBreak = 100;
                            }
                        })
                        .catch((err) => {
                            console.log('error :', err)
                            alert(err);
                        });
                    } else {
                        clearInterval(interval)
                    }
                }, 5000);
            }
        }catch(error){
            console.log("remainingWetList error :", error);
            alert('Approval Error : ' +  error)
            setMintLoading(false)
        }
    }

    // const loveApprovalWrite = useContractWrite({
    //     mode: 'recklesslyUnprepared',
    //     address: contractAddressLove,
    //     abi: abiLove,
    //     functionName: 'approve',
    //     args: [contractAddress, balanceCalc],
    //     // overrides: {  },
    //     onSuccess(data) {
    //         // console.log('love approval Success', data)
    //     },
    //     onSettled(data, error) {
    //         // console.log('gas approval Settled', { data, error })
    //         if(data){
    //             let isBreak = 0; 
    //             let interval = setInterval(async () => { 
    //                 if (isBreak <= 20) {
    //                     isBreak = isBreak+1;
    //                     // console.log('isBreak :', isBreak)
    //                     await provider.getTransactionReceipt(data.hash)
    //                     .then(function(transactionReceipt) {
    //                         // console.log(transactionReceipt);
    //                         let transactionReceipttx = transactionReceipt;
    //                         // console.log('transactionReceipttx status :', transactionReceipttx?.status)
    //                         if(transactionReceipttx?.status === 1){
    //                             loveMintWrite.write()
    //                             isBreak = 100;
    //                         }else if(transactionReceipttx?.status === 0){
    //                             toast.warning('Oops! Approval failed, try again')
    //                             isBreak = 100;
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         // console.log('error :', err)
    //                         // alert(err);
    //                     });
    //                 } else {
    //                     clearInterval(interval)
    //                 }
    //             }, 5000);
    //         }
    //     },
    //     onError(error) {
    //         // console.log('gas approval Error', error)
    //         alert('Approval Error: ' +  error)
    //         setMintLoading(false)
    //     },
    // })
    
    const loveMintWrite = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: contractAddress,
        abi: abi,
        functionName: 'mintByFungibleTokens',
        args: [mintingQty, 1],
        onSuccess(data) {
            // console.log('erc20Mint Success', data)
            if(data && data.hash) {
                setMintResultLink(baseUrlEtherscan+data.hash)
                // console.log("wet-list data.hash :", data.hash);
                setMintingQty(0);
                setMintLoading(false);
            }
        },
        onSettled(data, error) {
            // console.log('erc20Mint Settled', { data, error })
        },
        onError(error) {
            // console.log('erc20Mint Error', error)
            alert('Mint Error: ' +  error.code)
            setMintLoading(false);
        },
    })

    useEffect(()=> {
        return () => {
            setMintResultLink('')
        }
    },[]);

    const mintNftHandler = async () => {
        if(!address){
            toast.error("Please connect your wallet to proceed")
            return;
        }
        if((mintingQty && mintingAmount) || mintType === 'cham'){
            setMintLoading(true);
            setMintResultLink("");
            setMintResultLink('');
            if(data[6]){
                if(mintType === 'public'){
                    // console.log("publicMintWrite.write")
                    publicMintWrite.write()
                }else if(mintType === 'cham'){
                    // console.log("chamMintWrite.write")
                    chamMintWrite.write()
                }else if(mintType === 'erc20'){
                    if(ercMintType === 'gas'){
                        // console.log("gasMintWrite.write")
                        // setTimeout(() => {
                        //     setMintMsg('Minting with Gas token might take few minutes')
                        // }, 10000);
                        // setMintMsg('')
                        // console.log("balanceCalc :", balanceCalc);
                        let tempBalance = balanceCalc.toString()+"000000000000000000"
                        // console.log("tempBalance :", tempBalance);
                        const balance = (BigNumber.from(tempBalance.toString(16)))
                        gasApprovalWrite(balance)
                    }else if(ercMintType === 'love'){
                        // console.log("loveMintWrite.write")
                        // setTimeout(() => {
                        //     setMintMsg('Minting with love token might take few minutes')
                        // }, 10000);
                        // setMintMsg('')
                        let tempBalance = balanceCalc.toString()+"000000000000000000"
                        const balance = (BigNumber.from(tempBalance.toString(16)))
                        loveApprovalWrite(balance)
                    }
                }
            }else{
                alert("Oops!! Mint is not active")
                setMintLoading(false);
            }
        }else if(!mintingQty){
            toast.warning("invalid mint quantity")
        }else{
            toast.warning("Please connect your Wallet to proceed")
        }
    }

    const getMintDetails = async () => {
        try {
            await axios.get(`${base_url}/public/getTokenList?accountCode=${address}`)
                .then((response) => {
                    const chamTokens = response?.data?.response;
                    setListDataForHook(chamTokens);
                }
            )
        }
        catch (err) {
            // console.log("error", err);
        }
    }

    return (
        <div className='home'>
            <HeroView />
            {mintType === 'cham' ?
                <ChamMintSection 
                    mintNftHandler={mintNftHandler} 
                    mintingQty={mintingQty} 
                    setMintingQty={setMintingQty} 
                    selectedTokenIds={selectedTokenIds} 
                    setSelectedTokenIds={setSelectedTokenIds} 
                    listData={listData} 
                    listDataForHook={listDataForHook} 
                    setListData={setListData} 
                    getMintDetails={getMintDetails}
                    setActiveNav={setActiveNav}
                    mintLoading={mintLoading}
                />
                : mintType === 'erc20' ?
                    <ErcMintSection 
                        mintPrice={mintingAmount}
                        totalPrice={mintingAmount * mintingQty}
                        mintNftHandler={mintNftHandler} 
                        mintingQty={mintingQty} 
                        setMintingQty={setMintingQty} 
                        ercMintType={ercMintType} 
                        setErcMintType={setErcMintType} 
                        setMintResultLink={setMintResultLink}
                        setActiveNav={setActiveNav}
                        mintLoading={mintLoading}
                    />
                :
                <PublicMintSection 
                    mintPrice={mintingAmount}
                    totalPrice={mintingAmount * mintingQty}
                    mintNftHandler={mintNftHandler} 
                    mintingQty={mintingQty} 
                    setMintingQty={setMintingQty} 
                    setActiveNav={setActiveNav}
                    mintLoading={mintLoading}
                    mintMsg={mintMsg}
                />
            }
            <br />
            {mintResultLink &&
                <div className='d-flex justify-content-center'>
                    <a 
                        className="text-decoration-none h2" 
                        href={mintResultLink}
                        target="_blank"
                        // rel="noreferrer"
                    >
                        View Transaction <TfiArrowTopRight />
                    </a>
                </div>
            }
            <AboutMfers />
        </div>
    )
}

export default Home