import React from 'react'
/* import * as AppImg from '../../../static/constants/imgList' */

import IMG_1 from '../../../static/img/IMG_1.png'
import IMG_2 from '../../../static/img/IMG_2.PNG'
import IMG_6 from '../../../static/img/IMG_6.png'
import IMG_5 from '../../../static/img/IMG_5.PNG'


function Hero() {
    return (
        <div className='mt-3'>
            <div className="hero-wrapper w-100 px-5">
            <div className='d-flex flex-column flex-md-row mx-auto justify-content-center'>
            <div className='about-images mx-auto d-flex'>
               <div className='p-3'>
                    <img src={IMG_1} alt="" className="w-100" />
                </div>
                &nbsp;
                <div className='p-3'>
                    <img src={IMG_2} alt="" className="w-100" />
                </div>
            </div>
            <div className='about-images mx-auto d-flex'>
                <div className='p-3'>
                    <img src={IMG_5} alt="" className="w-100" />
                </div>
                &nbsp;
                <div className='p-3'>
                    <img src={IMG_6} alt="" className="w-100" />
                </div>
            </div>    
            </div>
                {/* <div className="hero-img d-flex flex-column flex-md-row mx-auto justify-content-center">
                      //<img src={AppImg.HeroImg} alt="" className="w-100" /> 
                    <div className='about-images mx-auto d-flex'>
                        <div className='w-25 m-3'>
                            <img src={IMG_1} alt="" className="w-100" />
                        </div>
                        <div className='w-25 m-3'>
                            <img src={IMG_2} alt="" className="w-100" />
                        </div>
                    </div>
                    <div className='about-images mx-auto d-flex'>
                        <div className='w-25 m-3'>
                            <img src={IMG_5} alt="" className="w-100" />
                        </div>
                        <div className='w-25 m-3'>
                            <img src={IMG_6} alt="" className="w-100" />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Hero