import React, { useState, useEffect } from 'react'
import { useAccount } from 'wagmi';
import { useExcludedChemTokenHook } from '../../hooks/useExcludedChemTokenHook';
import MintButton from '../MintButton/MintButton';
import { toast } from 'react-toastify';

function ChamMintSection({ 
    mintNftHandler, 
    setMintingQty, 
    selectedTokenIds, 
    setSelectedTokenIds, 
    listData, 
    listDataForHook, 
    setListData, 
    getMintDetails,
    setActiveNav,
    mintLoading
}) {
    const [selectedTokens, setSelectedTokens] = useState([]);
    
    const { address } = useAccount();

    const checkExcludeData = useExcludedChemTokenHook(listDataForHook);

    useEffect(() => {
        getMintDetails();
        setActiveNav('cham-mint');
        if(!address){
            window.history.go(-1)
            toast.warning('Please connect wallet to proceed')
        }
    }, []);

    useEffect(() =>{
        if(checkExcludeData?.length >= 0) {
            let tempArr = [];
            tempArr = listDataForHook?.map((item, i) => { 
                return { 
                    "token": item, 
                    "isExcluded": checkExcludeData[0][i]
                } 
            });
            setListData(tempArr);
        }
    },[checkExcludeData]);

    useEffect(() =>{
        if(listData?.length >= 0) {
            setSelectedTokens(listData?.filter((nft) => { return nft.isChecked}))
        }
    },[listData]);

    useEffect(() =>{
        if(selectedTokens?.length >= 0) {
            setSelectedTokenIds(selectedTokens?.map((nft) => { return nft.token}))
        }
    },[selectedTokens]);

    const selectChams = (e) => {
        const { name, checked } = e.target;
        let tempSelectedChams = [];
        tempSelectedChams = listData?.map((item) =>
            item.token == name ? { ...item, isChecked: checked } : item
        );
        setListData(tempSelectedChams);
    }

    useEffect(() => {
        setMintingQty(0);
    }, [])
    
    return (
        <div className='hero'>
            <div className="hero-wrapper">
                <div className="hero-mint-sec d-flex flex-column align-items-center justify-content-top mt-5">
                    <h1 className="public-title text-center mt-4">Mint cham-strippMFers</h1>
                    <h1 className="public-text text-center">Mint 1 Mfer for each Cham</h1>
                    <div className='d-flex flex-row justify-content-center flex-wrap w-100'>
                        {listData?.length > 0 ? 
                            listData?.map((data, index) => {
                                return (
                                    <div key={index} className="form-check cham-check-box py-1 d-flex justify-content-between align-items-center px-5">
                                        <input 
                                            className="form-check-input cursor-pointer OpenSansSansSerif-normal-black-20px cham-check-box-input" 
                                            type="checkbox" 
                                            name={data.token} 
                                            value={data?.isChecked} 
                                            id={data.token}
                                            onChange={(e) => {
                                                selectChams(e)
                                            }} 
                                            disabled={data.isExcluded}
                                        />
                                        <label className="form-check-label cursor-pointer OpenSansSansSerif-normal-black-20px align-items-center px-2" for={data.token}>
                                            {data.token}
                                        </label>
                                    </div>
                                )
                            })
                            :
                            <h1 className="public-text">No chameleon available</h1>
                        }
                    </div>
                    <MintButton 
                        mintNftHandler={mintNftHandler} 
                        isDisabled={selectedTokenIds?.length <= 0}
                        mintLoading={mintLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChamMintSection