import React from 'react';
import { FaTwitter,FaDiscord } from 'react-icons/fa';

function Footer(){
    return(
        <div className='footer-icons d-flex flex-column w-100 align-items-center py-4'>
            <div className='footer-items d-flex'>
                <a href='https://twitter.com/StrippM' target="_blank">
                   <FaTwitter  size={32}/>
                </a>
                <a href='https://discord.gg/x9vUUE7sWw' target="_blank" className='mx-4' >
                    <FaDiscord size={32}/>
                </a>
                <a href='https://opensea.io/collection/stripp-mfers' target="_blank">
                    <img className='opensealogo' src='https://opensea.io/static/images/logos/opensea.svg' />
                </a>
            </div>
            <h4 className='copyright-tag my-2'>CopyRight &#169; Strippmfers</h4>
        </div>
    )

}

export default Footer