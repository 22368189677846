import React, { useEffect, useState } from 'react'

function MintingInput({ mintingQty, setMintingQty }) {

    const [mintValue , setMintValue] = useState()

    const handleChange = (event) => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setMintValue(value)
        setMintingQty(value);
    }

    useEffect(() => {
        if(mintingQty === 0){
            setMintValue(null)
        }
    }, [mintingQty]);

    return (
        <div className="mint-input-wrapper mt-3">
            <input 
                className='mint-input form-control OpenSansSansSerif-normal-black-20px'
                type="number"
                min={0}
                max={50}
                placeholder='Enter Number For Mint'
                id="customRange3"
                onWheel={(e) => e.target.blur()}
                value={Number(mintValue).toString()}
                onChange={(e) => {handleChange(e)}}
            />
        </div>
    )
}

export default MintingInput