import React from 'react';
import { toast } from 'react-toastify';
import { useConnect, } from 'wagmi';
import { GrClose } from "react-icons/gr";
import { useEffect } from 'react';
import './UseWagmiConnectorsModal.css';

export default function UseWagmiConnectorsModal({ setIsModalClosed }) {
    const { connect, connectors, error, isLoading, pendingConnector } =  useConnect()

    useEffect(() => {
        if(error){
            toast.error(error.message);
        }
    }, [error]);

    return (
        <div className='connect-wallet-modal-wrapper d-flex justify-content-center align-items-center position-fixed'>
            <div className="connect-wallet-modal d-flex flex-column justify-content-between align-items-center mx-5 mx-md-0 pt-5">
                <div className="connect-wallet-modal-header d-flex justify-content-between align-items-start w-100 px-2 px-md-5">
                    <div className="d-flex flex-column align-items-start">
                        <h3 className="BebasNeueCursive-normal-blue-36px m-0">SELECT WALLET</h3>
                    </div>
                    <div className="connect-wallet-modal-close-btn cursor-pointer" onClick={() => {setIsModalClosed(false)}}>
                        <GrClose size={28}/>
                    </div>
                </div>
                <div className="modal-body w-100 p-2 p-md-5">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        {connectors.map((connector) => (
                            connector.ready ?
                            <button
                                className="btn-cwm btn btn-outline-secondary OpenSansSansSerif-normal-black-16px d-flex justify-content-center align-items-center w-100 py-2 my-2"
                                disabled={!connector.ready}
                                key={connector.id}
                                onClick={() => connect({ connector })}
                            >
                                {connector.name}
                                {isLoading &&
                                    connector.id === pendingConnector?.id &&
                                    ' (connecting)'}
                            </button>
                            :
                            null
                        ))}
                    </div>  
                </div>
            </div>
        </div>
    )
}
