import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, Navigate } from 'react-router-dom'  
import { configureChains, createClient, useAccount, WagmiConfig } from "wagmi";
import { mainnet, goerli } from 'wagmi/chains'
import Header from './components/common/Header/Header';
import Home from './hoc/Home';
import Footer from './components/common/Footer/Footer';
import UseWagmiConnectorsModal from './components/utils/UseWagmiConnectorsModal/UseWagmiConnectorsModal';
import 'react-toastify/dist/ReactToastify.css';
import './App.sass';


import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { useEffect } from 'react';

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [infuraProvider({ apiKey: '9fe32d520c064bb38d33baf316ed2eea' }), publicProvider()],
)

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
          chains,
          options: {
              appName: 'wagmi',
          },
      }),
      new WalletConnectConnector({
          chains,
          options: {
            qrcode: true,
          },
      })
  ],
  provider,
  webSocketProvider,
});

function App() {
  const [activeNav, setActiveNav] = useState('');
  const [isModalClosed, setIsModalClosed] = useState(false);

  const { address, isConnected } = useAccount();

  useEffect(() => {
    if(isConnected){
      setIsModalClosed(false);
    }
  }, [address, isConnected]);

  return (
    <WagmiConfig client={client}>
      <div className="App">
        <Header activeNav={activeNav} setActiveNav={setActiveNav} setIsModalClosed={setIsModalClosed}/>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Routes>
          <Route exact path='/' element={ <Navigate to="/mint" />} />
          <Route exact path='/*' element={ <Navigate to="/mint" />} />
          <Route exact path='/mint' element={<Home mintType='public' setActiveNav={setActiveNav}/>} />
          <Route exact path='/cham-mint' element={<Home mintType='cham' setActiveNav={setActiveNav}/>} />
          <Route exact path='/collab-mint' element={<Home mintType='erc20' setActiveNav={setActiveNav}/>} />
        </Routes>
        
      </div>
      { isModalClosed &&
        <UseWagmiConnectorsModal setIsModalClosed={setIsModalClosed}/>
      }
      <Footer/>
    </WagmiConfig>
  );
}

export default App;
