import React, { useState } from 'react'
import { FaEthereum, FaInfoCircle } from 'react-icons/fa';
import { CgAddR, CgRemoveR } from "react-icons/cg";
import { PulseLoader } from 'react-spinners';

function MintButton({ mintNftHandler, isDisabled, title, mintLoading }) {

    return (
        <div className="d-flex flex-column noselect">
            <button 
                className='btn btn-warning mt-5 py-3 px-5 w-100 mx-auto' 
                role="button"
                onClick={() => {mintNftHandler()}}
                disabled={isDisabled || mintLoading}
            >
                {mintLoading ?
                    <PulseLoader loading={mintLoading} color='#FFF'/>
                    :
                    title ? 
                        title 
                        : 
                        "MINT"
                }
            </button>
        </div>
    )
}

export default MintButton