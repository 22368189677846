import React from 'react'
import * as AppImg from '../../../static/constants/imgList'

import IMG_3 from '../../../static/img/IMG_3.PNG'
import IMG_4 from '../../../static/img/IMG_4.PNG'
import IMG_1 from '../../../static/img/IMG_1.png'
import IMG_8 from '../../../static/img/IMG_8.jpg'

import { ImPointRight } from 'react-icons/im';

function AboutMfers() {
    return (
        <div className='about-mfers mt-5 w-100'>
            {/* <h2 className="about-title">what are mfers</h2> */}
            
            <div className='d-flex flex-column flex-md-row mx-auto justify-content-center'>
            <div className='about-images mx-auto d-flex'>
               <div className='w-30 p-3'>
                    <img src={IMG_3} alt="" className="w-100" />
                </div>
                &nbsp;
                <div className='w-30 p-3'>
                    <img src={IMG_4} alt="" className="w-100" />
                </div>
            </div>
            <div className='about-images mx-auto d-flex'>
                <div className='w-30 p-3'>
                    <img src={IMG_8} alt="" className="w-100" />
                </div>
                &nbsp;
                <div className='w-30 p-3'>
                    <img src={IMG_1} alt="" className="w-100" />
                </div>
            </div>    
            </div>
            
            
                      
            <h1 className="mb-4 about-title mt-5">
            What are StrippMFers? 👀🤔 
            </h1>
            <p className="about-text mb-4">
            1st collection created by Cheddar Doodles 🎉
            </p>
            <p className="about-text mb-4">
            [A Timeline] November 2021 📅
            </p>
            <div className='about-points d-flex flex-column justify-content-start align-items-start'>
                <div className='d-flex justify-content-start align-items-start py-2'>
                    <p className='list-icon px-4'><ImPointRight/></p>
                    <p className='about-list-item about-points'> Cheddar learns about NFTs via chameleon collective.</p>
                </div>
                <div className='d-flex justify-content-start align-items-start py-2'>
                    <p className='list-icon px-4'><ImPointRight/></p>
                    <p className='about-list-item about-points'> A creative, long-time artist in conventional art, and a professional musician. </p>
                </div>
                <div className='d-flex justify-content-start align-items-start py-2'>
                    <p className='list-icon px-4'><ImPointRight/></p>
                    <p className='about-list-item about-points'> At the time he was building out his woodworking business, creating everything from props for major corporations, to wood signage and merch for mom and pops.</p>
                </div>
                <div className='d-flex justify-content-start align-items-start py-2'>
                    <p className='list-icon px-4'><ImPointRight/></p>
                    <p className='about-list-item about-points'> His drawing style was directly related to party games— like scrawl and some fun doodles. </p>
                </div>
                <div className='d-flex justify-content-start align-items-start py-2'>
                    <p className='list-icon px-4'><ImPointRight/></p>
                    <p className='about-list-item about-points'> Fast-forward to 2022, the main artist for Chameleon Collective decides to move on from the space, Cheddar was then asked to create new traits for the chameleons based on the lore and story; several hundred new traits are created and launched. </p>
                </div>
                <div className='d-flex justify-content-start align-items-start py-2'>
                    <p className='list-icon px-4'><ImPointRight/></p>
                    <p className='about-list-item about-points'> While at NFTNYC, Cheddar was able to connect with other NFT folks, fueled by that inspiration a new idea comes to life; this being close to the time Sartoshi decided to leave the space, and also when adult entertainment DAO projects were popping off.</p>
                </div>
            </div>
            <div className='d-flex justify-content-start align-items-start py-2'>
                    <p className="list-icon px-4"><ImPointRight/></p>
                    <p className="about-list-item about-points">
                    The perfect Meme project was born…StrippMfers
                    </p>
            </div>
            
            <p className="about-points about-text my-5">What better way to pair Mfers than memes poking fun at all the top projects, and what we all witness on a daily, and put that in a humorous visual form?
            </p>
            <p className="about-text my-5">
            StrippMfers is about bringing the funny, the obvious, the degen-ing, the laid-back attitude we miss from the early NFT days all in ONE PLACE: the StrippMfer Club 😏🔥
            </p>
            <p className="about-text my-5">
            Also: dicks, and tits 🤫
            </p>
            <p className="about-text my-5">
                You know, what folks pretend to be above, while surfing Pornhub and OnlyFans 😆
            </p>
            <p className="about-text my-5">
            So welcome, the poles are polished and ready, take a seat!
            {/* Need this in one of the above fonts */}
            </p>
        </div>
    )
}

export default AboutMfers